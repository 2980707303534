<template>
  <div class="columnd gap24 align-start">
    <h2 class="f18-700 c-lightOrange text-start">Infos</h2>
    <div class="columnd gap16 w100">
      <div class="rowd space-between w100">
        <span class="f14-400 c-darkGrey">Trade Partners</span>
        <span class="f14-400 c-ddGrey">37</span>
      </div>
      <div class="rowd space-between w100">
        <span class="f14-400 c-darkGrey">Trade</span>
        <span class="f14-400 c-ddGrey">854</span>
      </div>
      <div class="rowd space-between w100">
        <span class="f14-400 c-darkGrey">Joined</span>
        <span class="f14-400 c-ddGrey">2 Days ago</span>
      </div>
      <div class="rowd space-between w100">
        <span class="f14-400 c-darkGrey">Trusted by </span>
        <span class="f14-400 c-ddGrey">87 People</span>
      </div>
      <div class="rowd space-between w100">
        <span class="f14-400 c-darkGrey">Blocked by</span>
        <span class="f14-400 c-ddGrey">87 People</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Infos",
};
</script>

<style lang="scss" scoped></style>
