<template>
  <div class="columnd mini-container">
    <h2 class="f18-700 c-lightOrange text-start">
      Get Paid by inviteing your friends
    </h2>
    <div class="rowd space-between gap24">
      <div class="columnd gap16">
        <p class="f12-400 c-ddGrey text-start">
          You make money every time the people you introduce to Paxful, and the
          people they invite, buy Bitcoin.
        </p>
        <div class="selectCoin">
          <span class="f12-700 c-darkGrey text-start">Invitation Code</span>
          <div class="rowd space-between">
            <span class="f16-400 c-medGrey">78748</span>
            <inline-svg :src="require('../../../assets/Icons/copy.svg')" />
          </div>
        </div>
        <div class="rowd space-between">
          <div class="rowd gap4 align-center">
            <span class="f18-700 c-darkBlue">23</span>
            <span class="f14-400 c-darkBlue">People</span>
          </div>
          <div class="rowd gap4 align-center">
            <span class="f18-700 c-darkBlue">8474</span>
            <span class="f14-400 c-darkBlue">USD</span>
          </div>
        </div>
      </div>
      <img
        class="remove-img"
        src="../../../assets/Icons/InviteFriends.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "InviteFriends",
};
</script>

<style lang="scss" scoped>
@media only screen and(max-width:400px) {
  .remove-img {
    display: none;
  }
}
</style>
