<template>
  <div class="gap24 columnd w100">
    <PersonalInfo />
    <div class="info-row rowd gap24 w100">
      <Infos class="mini-container" style="flex: 1" />
      <InviteFriends style="flex: 1" />
    </div>
    <Table class="mini-container" />
  </div>
</template>

<script>
import Infos from "./Infos.vue";
import PersonalInfo from "../Profile/PersonalInfo.vue";
import Table from "../Profile/Table.vue";
import InviteFriends from "./InviteFriends.vue";
export default {
  components: { PersonalInfo, Table, Infos, InviteFriends },
  name: "Dashboard",
};
</script>

<style lang="scss" scoped>
.info-row {
  flex-wrap: wrap;
}
@media only screen and(max-width:800px) {
  .info-row {
    flex-direction: column !important;
  }
}
</style>
